import React, { useContext, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import AppHeader from "./AppHeader";
import { Route, Routes, useParams } from "react-router-dom";
import StartPage from "./StartPage";
import FormArea from "./FormArea";
import Completed from "./Completed";
import CompletedError from "./CompletedError";
import NotFound from "./NotFound";
import Context from "./Context";

function SelectedFirmWrapper() {
    const { firmsList, selectedFirm, setSelectedFirm } = useContext(Context);
    const { firm } = useParams();
    const firmObj = firmsList.find((x) => x.unique_url === firm);
    useEffect(() => {
        setSelectedFirm(firmObj);
    }, [firm]);
    return (
        <Box backgroundColor={"white"} id="firm_wrapper">
            {selectedFirm === null ? (
                <Box
                    width={"100vw"}
                    height={"100vh"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <AppHeader />
                    <Box width={"100vw"} minHeight={"calc(100vh - 64px)"}>
                        <Routes>
                            <Route path="/" element={<StartPage />} />
                            <Route path="/form" element={<FormArea />} />
                            <Route path="/completed" element={<Completed />} />
                            <Route
                                path="/completederror"
                                element={<CompletedError />}
                            />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Box>
                </>
            )}
        </Box>
    );
}

export default SelectedFirmWrapper;

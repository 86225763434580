import React, { useState, useContext } from "react";
import { Box, CircularProgress, Divider, Tooltip } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils";
import { saveAs } from "file-saver";
import axios from "axios";
import Context from "./Context";
import SaveFormArea from "./SaveFormArea";

const api_url =
    "https://798qhvmd6a.execute-api.us-west-2.amazonaws.com/production";
const dev_api_url = "http://localhost:8000";

// CHANGE HERE WHEN DOING DEV WORK
const current_api_url = api_url;

const defaultValues = {
    // Step 1
    state_caps: "OREGON",
    date: "",
    firstname: "",
    lastname: "",
    name: "",
    name_caps: "",
    street: "",
    city: "",
    county: "",
    state: "",
    age_self: "",
    dep_name_1: "",
    dep_age_1: "",
    dep_rel_1: "",
    dep_name_2: "",
    dep_age_2: "",
    dep_rel_2: "",
    dep_name_3: "",
    dep_age_3: "",
    dep_rel_3: "",
    // Remove 4,5,6 once removed in doc
    dep_name_4: "",
    dep_age_4: "",
    dep_rel_4: "",
    dep_name_5: "",
    dep_age_5: "",
    dep_rel_5: "",
    dep_name_6: "",
    dep_age_6: "",
    dep_rel_6: "",
    // Step 2
    q4: "",
    q5: "",
    q6a: "",
    q6b: "",
    q6c1: "",
    q6c: "",
    q7a: "",
    q7b: "",
    q7c: "",
    q8a: "",
    q8b: "",
    q9a: "",
    q9b: "",
    q9c: "",
    q9d: "",
    // Step 3
    q11: "",
    q11a: "",
    q11b: "",
    q11c: "",
    q11d1: "",
    q11d2: "",
    q11e1: "",
    q11e2: "",
    q11f1: "",
    q11f2: "",
    q11g: "",
    q11h: "",
    q11i: "",
    q11j: "",
    q12: "",
    q12a: "",
    q12b: "",
    q12c: "",
    q12d: "",
    q13: "",
    // Step 4
    q14ai: "",
    q14aii: "",
    q14aiii: "",
    q14aiv: "",
    q14av: "",
    q14avi: "",
    q14b: "",
    q14b1: "",
    q14b2: "",
    q14b3: "",
    q14c: "",
    q15ai: "",
    q15aii1: "",
    q15aii2: "",
    q15aiii: "",
    q15aiv1: "",
    q15aiv2: "",
    q15av: "",
    q15avi1: "",
    q15avi2: "",
    q15avii1a: "",
    q15avii1b: "",
    q15avii2a: "",
    q15avii2b: "",
    q15avii3a: "",
    q15avii3b: "",
    q15di: "",
    q15dii: "",
    q15diii: "",
    q15div: "",
    q15dv: "",
    q15ei: "",
    q15eii: "",
    q15eiii: "",
    q15fi: "",
    q15fii1: "",
    q15fii2: "",
    q15fiii1: "",
    q15fiii2: "",
    q15fiv1: "",
    q15fiv2: "",
    q15fv1: "",
    q15fv2: "",
    q15fvi1: "",
    q15fvi2: "",
    q15fvii: "",
    q15fviii1: "",
    q15fviii2: "",
    q16: "",
    q17: "",
    // Step 5
    q18a: "",
    q18b: "",
    q18c: "",
    q18c1: "",
    q18d: "",
    q18e: "",
    q18f: "",
    q18g: "",
    q19a: "",
    q19b: "",
    q19c: "",
    q19d: "",
    q19e: "",
    q19f: "",
    q19g: "",
    q19h: "",
    q19i: "",
    // Step 6
    q21a: "",
    q21b1: "",
    q21b2: "",
    q21c1: "",
    q21c2: "",
    q21c3: "",
    q21c4: "",
    q22a: "",
    q22b: "",
    q23: "",
    q24: "",
    q25: "",
    q26: "",
    // Step 7
    q27a: "",
    q27b: "",
    q27c: "",
    q27d: "",
    q28a: "",
    q28b: "",
    q28c: "",
    q29: "",
    q30: "",
    q30_name_1: "",
    q30_state_1: "",
    q30_type_1: "",
    q30_name_2: "",
    q30_state_2: "",
    q30_type_2: "",
    q30_name_3: "",
    q30_state_3: "",
    q30_type_3: "",
    q31: "",
    // Step 8
    q32: "",
    q33: false,
};

function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}

const steps = [
    "Personal Info",
    "Student Loan Info",
    "Current Income",
    "Monthly Expenses",
    "Future Loan Payments",
    "Prior Efforts",
    "Current Assets",
    "Finish",
];

function FormArea() {
    const getTotalGrossIncome = () => {
        let total = 0;
        total = !isNaN(parseFloat(formValues.q11a))
            ? (total += parseFloat(formValues.q11a))
            : total;
        total = !isNaN(parseFloat(formValues.q11b))
            ? (total += parseFloat(formValues.q11b))
            : total;
        total = !isNaN(parseFloat(formValues.q11c))
            ? (total += parseFloat(formValues.q11c))
            : total;
        total = !isNaN(parseFloat(formValues.q11d1))
            ? (total += parseFloat(formValues.q11d1))
            : total;
        total = !isNaN(parseFloat(formValues.q11e1))
            ? (total += parseFloat(formValues.q11e1))
            : total;
        total = !isNaN(parseFloat(formValues.q11f1))
            ? (total += parseFloat(formValues.q11f1))
            : total;
        total = !isNaN(parseFloat(formValues.q11g))
            ? (total += parseFloat(formValues.q11g))
            : total;
        total = !isNaN(parseFloat(formValues.q11h))
            ? (total += parseFloat(formValues.q11h))
            : total;
        total = !isNaN(parseFloat(formValues.q11i))
            ? (total += parseFloat(formValues.q11i))
            : total;
        total = !isNaN(parseFloat(formValues.q11j))
            ? (total += parseFloat(formValues.q11j))
            : total;
        return total;
    };

    const { selectedFirm } = useContext(Context);
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        if (activeStep + 1 === steps.length) {
            submitForm();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            document.getElementById("form_card").scrollTop = 0;
        }
    };

    const formalizeData = () => {
        let currentFormValues = { ...formValues };
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;
        const formattedToday = mm + "/" + dd + "/" + yyyy;
        currentFormValues["date"] = formattedToday;
        currentFormValues[
            "name"
        ] = `${currentFormValues["firstname"]} ${currentFormValues["lastname"]}`;
        currentFormValues["name_caps"] =
            currentFormValues["name"].toUpperCase();
        currentFormValues["q4"] =
            currentFormValues["q4"] === "yes" ? "NO" : "YES";
        currentFormValues["q5"] =
            currentFormValues["q5"] === ""
                ? "__________"
                : currentFormValues["q5"];
        currentFormValues["q6a"] =
            currentFormValues["q6a"] === ""
                ? "__________"
                : currentFormValues["q6a"];
        currentFormValues["q6b"] =
            currentFormValues["q6b"] === ""
                ? "__________"
                : currentFormValues["q6b"];
        currentFormValues["q6c1"] = currentFormValues["q6c"] !== "" ? "X" : "_";
        currentFormValues["q6c"] =
            currentFormValues["q6c"] === ""
                ? "__________"
                : currentFormValues["q6c"];
        currentFormValues["q7a"] =
            currentFormValues["q7a"] === ""
                ? "__________"
                : currentFormValues["q7a"];
        currentFormValues["q7b"] =
            currentFormValues["q7b"] === ""
                ? "__________"
                : currentFormValues["q7b"];
        currentFormValues["q7c"] =
            currentFormValues["q7c"] === ""
                ? "__________"
                : currentFormValues["q7c"];
        currentFormValues["q8a"] =
            currentFormValues["q8a"] === ""
                ? "__________"
                : currentFormValues["q8a"];
        currentFormValues["q8b"] =
            currentFormValues["q8b"] === ""
                ? "__________"
                : currentFormValues["q8b"];
        currentFormValues["q9a"] =
            currentFormValues["q9a"] === "no" ? "X" : "_";
        currentFormValues["q9b"] =
            currentFormValues["q9b"] === ""
                ? "__________"
                : currentFormValues["q9b"];
        currentFormValues["q9c"] =
            currentFormValues["q9c"] === ""
                ? "__________"
                : currentFormValues["q9c"];
        currentFormValues["q9d"] =
            currentFormValues["q9d"] === ""
                ? "__________"
                : currentFormValues["q9d"];
        currentFormValues["q11"] = getTotalGrossIncome();
        currentFormValues["q11a"] =
            currentFormValues["q11a"] === ""
                ? "__________"
                : currentFormValues["q11a"];
        currentFormValues["q11b"] =
            currentFormValues["q11b"] === ""
                ? "__________"
                : currentFormValues["q11b"];
        currentFormValues["q11c"] =
            currentFormValues["q11c"] === ""
                ? "__________"
                : currentFormValues["q11c"];
        currentFormValues["q11d1"] =
            currentFormValues["q11d1"] === ""
                ? "__________"
                : currentFormValues["q11d1"];
        currentFormValues["q11d2"] =
            currentFormValues["q11d2"] === ""
                ? "__________"
                : currentFormValues["q11d2"];
        currentFormValues["q11e1"] =
            currentFormValues["q11e1"] === ""
                ? "__________"
                : currentFormValues["q11e1"];
        currentFormValues["q11e2"] =
            currentFormValues["q11e2"] === ""
                ? "__________"
                : currentFormValues["q11e2"];
        currentFormValues["q11f1"] =
            currentFormValues["q11f1"] === ""
                ? "__________"
                : currentFormValues["q11f1"];
        currentFormValues["q11f2"] =
            currentFormValues["q11f2"] === ""
                ? "__________"
                : currentFormValues["q11f2"];
        currentFormValues["q11g"] =
            currentFormValues["q11g"] === ""
                ? "__________"
                : currentFormValues["q11g"];
        currentFormValues["q11h"] =
            currentFormValues["q11h"] === ""
                ? "__________"
                : currentFormValues["q11h"];
        currentFormValues["q11i"] =
            currentFormValues["q11i"] === ""
                ? "__________"
                : currentFormValues["q11i"];
        currentFormValues["q11j"] =
            currentFormValues["q11j"] === ""
                ? "__________"
                : currentFormValues["q11j"];
        currentFormValues["q12a"] =
            currentFormValues["q12"] === "q12a" ? "X" : "_";
        currentFormValues["q12b"] =
            currentFormValues["q12"] === "q12b" ? "X" : "_";
        currentFormValues["q12c"] =
            currentFormValues["q12"] === "q12c" ? "X" : "_";
        currentFormValues["q14b1"] =
            currentFormValues["q14b"] === "yes" ? "✓" : "_";
        currentFormValues["q14b2"] =
            currentFormValues["q14b"] === "no" ? "✓" : "_";
        currentFormValues["q14b3"] =
            currentFormValues["q14b3"] === ""
                ? "___________________________________"
                : currentFormValues["q14b3"];
        currentFormValues["q14c"] =
            currentFormValues["q14c"] === ""
                ? "___________________________________"
                : currentFormValues["q14c"];
        currentFormValues["q15ai"] =
            currentFormValues["q15ai"] === ""
                ? "__________"
                : currentFormValues["q15ai"];
        currentFormValues["q15aii1"] =
            currentFormValues["q15aii1"] === ""
                ? "__________"
                : currentFormValues["q15aii1"];
        currentFormValues["q15aiii"] =
            currentFormValues["q15aiii"] === ""
                ? "__________"
                : currentFormValues["q15aiii"];
        currentFormValues["q15aiv1"] =
            currentFormValues["q15aiv1"] === ""
                ? "__________"
                : currentFormValues["q15aiv1"];
        currentFormValues["q15av"] =
            currentFormValues["q15av"] === ""
                ? "__________"
                : currentFormValues["q15av"];
        currentFormValues["q15avi1"] =
            currentFormValues["q15avi1"] === ""
                ? "__________"
                : currentFormValues["q15avi1"];
        currentFormValues["q15avii1a"] =
            currentFormValues["q15avii1a"] === ""
                ? "__________"
                : currentFormValues["q15avii1a"];
        currentFormValues["q15avii1b"] =
            currentFormValues["q15avii1b"] === ""
                ? "__________"
                : currentFormValues["q15avii1b"];
        currentFormValues["q15avii2a"] =
            currentFormValues["q15avii2a"] === ""
                ? "__________"
                : currentFormValues["q15avii2a"];
        currentFormValues["q15avii2b"] =
            currentFormValues["q15avii2b"] === ""
                ? "__________"
                : currentFormValues["q15avii2b"];
        currentFormValues["q15avii3a"] =
            currentFormValues["q15avii3a"] === ""
                ? "__________"
                : currentFormValues["q15avii3a"];
        currentFormValues["q15avii3b"] =
            currentFormValues["q15avii3b"] === ""
                ? "__________"
                : currentFormValues["q15avii3b"];
        currentFormValues["q15di"] =
            currentFormValues["q15di"] === ""
                ? "__________"
                : currentFormValues["q15di"];
        currentFormValues["q15dii"] =
            currentFormValues["q15dii"] === ""
                ? "__________"
                : currentFormValues["q15dii"];
        currentFormValues["q15diii"] =
            currentFormValues["q15diii"] === ""
                ? "__________"
                : currentFormValues["q15diii"];
        currentFormValues["q15div"] =
            currentFormValues["q15div"] === ""
                ? "__________"
                : currentFormValues["q15div"];
        currentFormValues["q15dv"] =
            currentFormValues["q15dv"] === ""
                ? "__________"
                : currentFormValues["q15dv"];
        currentFormValues["q15ei"] =
            currentFormValues["q15ei"] === ""
                ? "__________"
                : currentFormValues["q15ei"];
        currentFormValues["q15eii"] =
            currentFormValues["q15eii"] === ""
                ? "__________"
                : currentFormValues["q15eii"];
        currentFormValues["q15eiii"] =
            currentFormValues["q15eiii"] === ""
                ? "__________"
                : currentFormValues["q15eiii"];
        currentFormValues["q15fi"] =
            currentFormValues["q15fi"] === ""
                ? "__________"
                : currentFormValues["q15fi"];
        currentFormValues["q15fii1"] =
            currentFormValues["q15fii1"] === ""
                ? "__________"
                : currentFormValues["q15fii1"];
        currentFormValues["q15fii2"] =
            currentFormValues["q15fii2"] === ""
                ? "___________________________________"
                : currentFormValues["q15fii2"];
        currentFormValues["q15fiii1"] =
            currentFormValues["q15fiii1"] === ""
                ? "__________"
                : currentFormValues["q15fiii1"];
        currentFormValues["q15fiv1"] =
            currentFormValues["q15fiv1"] === ""
                ? "__________"
                : currentFormValues["q15fiv1"];
        currentFormValues["q15fv1"] =
            currentFormValues["q15fv1"] === ""
                ? "__________"
                : currentFormValues["q15fv1"];
        currentFormValues["q15fv2"] =
            currentFormValues["q15fv2"] === ""
                ? "___________________________________"
                : currentFormValues["q15fv2"];
        currentFormValues["q15fvi1"] =
            currentFormValues["q15fvi1"] === ""
                ? "__________"
                : currentFormValues["q15fvi1"];
        currentFormValues["q15fvii"] =
            currentFormValues["q15fvii"] === ""
                ? "__________"
                : currentFormValues["q15fvii"];
        currentFormValues["q15fviii1"] =
            currentFormValues["q15fviii1"] === ""
                ? "__________"
                : currentFormValues["q15fviii1"];
        currentFormValues["q15fviii2"] =
            currentFormValues["q15fviii2"] === ""
                ? "___________________________________"
                : currentFormValues["q15fviii2"];
        currentFormValues["q16"] =
            currentFormValues["q16"] === ""
                ? "__________"
                : currentFormValues["q16"];
        currentFormValues["q17"] =
            currentFormValues["q17"] === ""
                ? "___________________________________"
                : currentFormValues["q17"];
        currentFormValues["q18a"] =
            currentFormValues["q18a"] === "yes" ? "✓" : "_";
        currentFormValues["q18b"] =
            currentFormValues["q18b"] === "yes" ? "✓" : "_";
        currentFormValues["q18c"] =
            currentFormValues["q18c"] === "yes" ? "✓" : "_";
        currentFormValues["q18c1"] =
            currentFormValues["q18c1"] === ""
                ? "___________________________________"
                : currentFormValues["q18c1"];
        currentFormValues["q18d"] =
            currentFormValues["q18d"] === "yes" ? "✓" : "_";
        currentFormValues["q18e"] =
            currentFormValues["q18e"] === ""
                ? "___________________________________"
                : currentFormValues["q18e"];
        currentFormValues["q18f"] =
            currentFormValues["q18f"] === "yes" ? "✓" : "_";
        currentFormValues["q18g"] =
            currentFormValues["q18g"] === ""
                ? "___________________________________"
                : currentFormValues["q18g"];
        currentFormValues["q19a"] =
            currentFormValues["q19a"] === "yes" ? "✓" : "_";
        currentFormValues["q19b"] =
            currentFormValues["q19b"] === ""
                ? "___________________________________"
                : currentFormValues["q19b"];
        currentFormValues["q19c"] =
            currentFormValues["q19c"] === "yes" ? "✓" : "_";
        currentFormValues["q19d"] =
            currentFormValues["q19d"] === "yes" ? "✓" : "_";
        currentFormValues["q19e"] =
            currentFormValues["q19e"] === ""
                ? "___________________________________"
                : currentFormValues["q19e"];
        currentFormValues["q19f"] =
            currentFormValues["q19f"] === "yes" ? "✓" : "_";
        currentFormValues["q19g"] =
            currentFormValues["q19g"] === ""
                ? "___________________________________"
                : currentFormValues["q19g"];
        currentFormValues["q19h"] =
            currentFormValues["q19h"] === "yes" ? "✓" : "_";
        currentFormValues["q19i"] =
            currentFormValues["q19i"] === ""
                ? "___________________________________"
                : currentFormValues["q19i"];
        currentFormValues["q21b1"] =
            currentFormValues["q21b2"] === "" ? "_" : "✓";
        currentFormValues["q21b2"] =
            currentFormValues["q21b2"] === ""
                ? "__________"
                : currentFormValues["q21b2"];
        currentFormValues["q21c1"] =
            currentFormValues["q21c2"] === "" &&
            currentFormValues["q21c3"] === "" &&
            currentFormValues["q21c4"] === ""
                ? "_"
                : "✓";
        currentFormValues["q21c2"] =
            currentFormValues["q21c2"] === ""
                ? "__________"
                : currentFormValues["q21c2"];
        currentFormValues["q21c3"] =
            currentFormValues["q21c3"] === ""
                ? "__________"
                : currentFormValues["q21c3"];
        currentFormValues["q21c4"] =
            currentFormValues["q21c4"] === ""
                ? "__________"
                : currentFormValues["q21c4"];
        currentFormValues["q22a"] =
            currentFormValues["q22a"] === ""
                ? "__________"
                : currentFormValues["q22a"];
        currentFormValues["q22b"] =
            currentFormValues["q22b"] === ""
                ? "__________"
                : currentFormValues["q22b"];
        currentFormValues["q23"] =
            currentFormValues["q23"] === ""
                ? "__________"
                : currentFormValues["q23"];
        currentFormValues["q24"] =
            currentFormValues["q24"] === ""
                ? "___________________________________"
                : currentFormValues["q24"];
        currentFormValues["q25"] =
            currentFormValues["q25"] === ""
                ? "___________________________________"
                : currentFormValues["q25"];
        currentFormValues["q26"] =
            currentFormValues["q26"] === ""
                ? "___________________________________"
                : currentFormValues["q26"];
        currentFormValues["q27a"] =
            currentFormValues["q27a"] === ""
                ? "__________"
                : currentFormValues["q27a"];
        currentFormValues["q27b"] =
            currentFormValues["q27b"] === ""
                ? "__________"
                : currentFormValues["q27b"];
        currentFormValues["q27c"] =
            currentFormValues["q27c"] === ""
                ? "__________"
                : `${currentFormValues["q27c"]}`;
        currentFormValues["q27d"] =
            currentFormValues["q27d"] === ""
                ? "__________"
                : `${currentFormValues["q27d"]}`;
        currentFormValues["q28a"] =
            currentFormValues["q28a"] === ""
                ? "__________"
                : currentFormValues["q28a"];
        currentFormValues["q28b"] =
            currentFormValues["q28b"] === ""
                ? "__________"
                : `${currentFormValues["q28b"]}`;
        currentFormValues["q28c"] =
            currentFormValues["q28c"] === ""
                ? "__________"
                : `${currentFormValues["q28c"]}`;
        currentFormValues["q29"] =
            currentFormValues["q29"] === ""
                ? "__________"
                : `$${currentFormValues["q29"]}`;
        currentFormValues["q30a1"] =
            currentFormValues["q30a1"] === ""
                ? "N/A"
                : `${currentFormValues["q30a1"]}`;
        currentFormValues["q30a2"] =
            currentFormValues["q30a2"] === ""
                ? "N/A"
                : `${currentFormValues["q30a2"]}`;
        currentFormValues["q30a3"] =
            currentFormValues["q30a3"] === ""
                ? "N/A"
                : `${currentFormValues["q30a3"]}`;
        currentFormValues["q31"] =
            currentFormValues["q31"] === ""
                ? "__________"
                : currentFormValues["q31"];
        currentFormValues["q32"] =
            currentFormValues["q32"] === ""
                ? "___________________________________"
                : currentFormValues["q32"];
        return currentFormValues;
    };

    const submitForm = () => {
        setLoading(true);
        let form_values = formalizeData();
        loadFile(
            `${process.env.PUBLIC_URL}/attestation_form.docx`,
            function (error, content) {
                if (error) {
                    throw error;
                }
                var zip = new PizZip(content);
                var doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });
                doc.setData(form_values);
                try {
                    doc.render();
                } catch (error) {
                    function replaceErrors(key, value) {
                        if (value instanceof Error) {
                            return Object.getOwnPropertyNames(value).reduce(
                                function (error, key) {
                                    error[key] = value[key];
                                    return error;
                                },
                                {}
                            );
                        }
                        return value;
                    }
                    if (
                        error.properties &&
                        error.properties.errors instanceof Array
                    ) {
                        const errorMessages = error.properties.errors
                            .map(function (error) {
                                return error.properties.explanation;
                            })
                            .join("\n");
                        console.log("errorMessages", errorMessages);
                    }
                    throw error;
                }
                var blob = doc.getZip().generate({
                    type: "blob",
                    mimeType:
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                // // NOTE: PROD MODE ONLY
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    var base64String = reader.result;
                    let byte_string = base64String.substr(
                        base64String.indexOf(",") + 1
                    );
                    axios
                        .post(`${current_api_url}/api/submit`, {
                            byte_string: byte_string,
                            file_name: `${formValues.firstname}_${formValues.lastname}_attestationform.docx`,
                            client_name: `${formValues.firstname} ${formValues.lastname}`,
                            email_receiver: selectedFirm.secretary_email,
                        })
                        .then((response) => {
                            setLoading(false);
                            navigate(`/${selectedFirm.unique_url}/completed`);
                        })
                        .catch((error) => {
                            saveAs(
                                blob,
                                `${formValues.firstname}_${formValues.lastname}_attestationform.docx`
                            );
                            navigate(
                                `/${selectedFirm.unique_url}/completederror`
                            );
                        });
                };
                // // NOTE: DEV MODE ONLY
                // saveAs(
                //     blob,
                //     `${formValues.firstname}_${formValues.lastname}_attestationform.docx`
                // );
            }
        );
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        document.getElementById("form_card").scrollTop = 0;
    };

    const [formValues, setFormValues] = useState(defaultValues);

    const handleInputChange = (e) => {
        if (e.target.id === "q33-input") {
            const { name, checked } = e.target;
            setFormValues({
                ...formValues,
                [name]: checked,
            });
        } else {
            const { name, value } = e.target;
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    const [loading, setLoading] = useState(false);

    const isNextButtonDisabled = () => {
        // // NOTE: DEV MODE ONLY
        // return false;
        if (loading) {
            return true;
        }
        if (activeStep === steps.length - 1 && formValues.q33 === false) {
            return true;
        }
        if (activeStep === 0) {
            if (formValues.firstname === "") {
                return true;
            }
            if (formValues.lastname === "") {
                return true;
            }
            if (formValues.street === "") {
                return true;
            }
            if (formValues.city === "") {
                return true;
            }
            if (formValues.county === "") {
                return true;
            }
            if (formValues.state === "") {
                return true;
            }
            if (formValues.age_self === "") {
                return true;
            }
        }
        if (activeStep === 1) {
            if (formValues.q4 === "") {
                return true;
            }
            if (formValues.q9a === "yes") {
                if (formValues.q9b === "") {
                    return true;
                }
                if (formValues.q9c === "") {
                    return true;
                }
                if (formValues.q9d === "") {
                    return true;
                }
            }
        }
        if (activeStep === 2) {
            if (
                formValues.q11a === "" &&
                formValues.q11b === "" &&
                formValues.q11c === "" &&
                formValues.q11d1 === "" &&
                formValues.q11e1 === "" &&
                formValues.q11f1 === "" &&
                formValues.q11g === "" &&
                formValues.q11h === "" &&
                formValues.q11i === "" &&
                formValues.q11j === ""
            ) {
                return true;
            }
            if (formValues.q12 === "") {
                return true;
            }
        }
        if (activeStep === 3) {
            if (formValues.q14ai === "") {
                return true;
            }
            if (formValues.q14aii === "") {
                return true;
            }
            if (formValues.q14aiii === "") {
                return true;
            }
            if (formValues.q14aiv === "") {
                return true;
            }
            if (formValues.q14av === "") {
                return true;
            }
            if (formValues.q14avi === "") {
                return true;
            }
            if (formValues.q14b === "") {
                return true;
            }
            if (formValues.q16 === "") {
                return true;
            }
        }
        if (activeStep === 4) {
            if (formValues.q18a === "") {
                return true;
            }
            if (formValues.q18b === "") {
                return true;
            }
            if (formValues.q18c === "") {
                return true;
            }
            if (formValues.q18d === "") {
                return true;
            }
            if (formValues.q18f === "") {
                return true;
            }
        }
        return false;
    };

    const getNextButtonTooltip = () => {
        if (activeStep === 0) {
            let content = (
                <React.Fragment>
                    <Box fontSize={11}>
                        The following fields are required: <br></br>
                        <ul>
                            <li>First Name</li>
                            <li>Last Name</li>
                            <li>Street Address</li>
                            <li>City</li>
                            <li>County</li>
                            <li>State</li>
                            <li>Age</li>
                        </ul>
                    </Box>
                </React.Fragment>
            );
            return content;
        }
        if (activeStep === 1) {
            if (formValues.q4 === "") {
                let content = (
                    <React.Fragment>
                        <Box fontSize={11}>
                            Answers to the following are required: <br></br>
                            <ul>
                                <li>Question 1</li>
                            </ul>
                        </Box>
                    </React.Fragment>
                );
                return content;
            }
            if (formValues.q9a === "yes") {
                let content = (
                    <React.Fragment>
                        <Box fontSize={11}>
                            Answers to the following are required: <br></br>
                            <ul>
                                <li>Question 5 - a</li>
                                <li>Question 5 - b</li>
                                <li>Question 5 - c</li>
                            </ul>
                        </Box>
                    </React.Fragment>
                );
                return content;
            }
        }
        if (activeStep === 2) {
            let content = (
                <React.Fragment>
                    <Box fontSize={11}>
                        Answers to the following are required: <br></br>
                        <ul>
                            <li>Question 1 (total gross income)</li>
                            <li>Question 2</li>
                        </ul>
                    </Box>
                </React.Fragment>
            );
            return content;
        }
        if (activeStep === 3) {
            let content = (
                <React.Fragment>
                    <Box fontSize={11}>
                        Answers to the following are required: <br></br>
                        <ul>
                            <li>Question 1 (parts a - f)</li>
                            <li>Question 2</li>
                            <li>Question 7</li>
                        </ul>
                    </Box>
                </React.Fragment>
            );
            return content;
        }
        if (activeStep === 4) {
            let content = (
                <React.Fragment>
                    <Box fontSize={11}>
                        Answers to the following are required: <br></br>
                        <ul>
                            <li>Question 1 (parts a - e)</li>
                        </ul>
                    </Box>
                </React.Fragment>
            );
            return content;
        }
        if (activeStep === steps.length - 1 && formValues.q33 === false) {
            let content = (
                <React.Fragment>
                    <Box fontSize={11}>
                        You must check the box declaring that the information
                        provided is true and correct.
                    </Box>
                </React.Fragment>
            );
            return content;
        }
    };

    return (
        <Box width={"100%"} display={"flex"} height={"calc(100vh - 64px)"}>
            <Box
                sx={{ maxHeight: "calc(100vh - 64px)", padding: 4 }}
                display={"flex"}
                flexDirection={"column"}
            >
                <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    sx={{ flex: 1, marginBottom: 3 }}
                >
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Divider />
                <SaveFormArea
                    formValues={formValues}
                    activeStep={activeStep}
                    setFormValues={setFormValues}
                    setActiveStep={setActiveStep}
                />
            </Box>
            <Divider orientation="vertical" />
            <Box
                sx={{
                    maxHeight: "calc(100vh - 64px)",
                    flex: 1,
                }}
                display={"flex"}
                flexDirection={"column"}
            >
                <Box textAlign={"center"} paddingY={1} fontWeight={600}>
                    Step {activeStep + 1}: {steps[activeStep]}
                </Box>
                <Divider orientation="horizontal" />
                <Box flex={1} paddingX={3} overflow={"auto"} id="form_card">
                    {activeStep === 0 && (
                        <Step1
                            formValues={formValues}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {activeStep === 1 && (
                        <Step2
                            formValues={formValues}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {activeStep === 2 && (
                        <Step3
                            formValues={formValues}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {activeStep === 3 && (
                        <Step4
                            formValues={formValues}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {activeStep === 4 && (
                        <Step5
                            formValues={formValues}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {activeStep === 5 && (
                        <Step6
                            formValues={formValues}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {activeStep === 6 && (
                        <Step7
                            formValues={formValues}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {activeStep === 7 && (
                        <Step8
                            formValues={formValues}
                            handleInputChange={handleInputChange}
                        />
                    )}
                </Box>
                <Divider orientation="horizontal" />
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    paddingY={2}
                    paddingX={3}
                    justifyContent={"flex-end"}
                >
                    <Button
                        color="secondary"
                        variant={"outlined"}
                        size={"small"}
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 3 }}
                    >
                        Back
                    </Button>
                    <Tooltip
                        title={
                            isNextButtonDisabled() ? getNextButtonTooltip() : ""
                        }
                    >
                        <span>
                            <Button
                                disabled={isNextButtonDisabled()}
                                onClick={handleNext}
                                variant={"contained"}
                                size={"small"}
                                color={
                                    activeStep === steps.length - 1
                                        ? "success"
                                        : "primary"
                                }
                            >
                                {activeStep === steps.length - 1
                                    ? "Submit"
                                    : "Next"}
                                {loading && (
                                    <CircularProgress
                                        size={20}
                                        sx={{ marginLeft: 2 }}
                                        color={"grey"}
                                    />
                                )}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
}

export default FormArea;

import React from "react";
import {
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
} from "@mui/material";

function Step5({ formValues, handleInputChange }) {
    return (
        <Box marginY={3}>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"100%"}>
                    1. Please answer the following questions:
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"45%"} marginLeft={4}>
                    a. I am over the age of 65.
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q18a"
                        value={formValues.q18a}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"45%"} marginLeft={4}>
                    b. The student loans I am seeking to discharge have been in
                    repayment status for at least 10 years (excluding any period
                    during which I was enrolled as a student).
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q18b"
                        value={formValues.q18b}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"45%"} marginLeft={4}>
                    c. I did not complete the education for which I incurred the
                    student loan[s].
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q18c"
                        value={formValues.q18c}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"45%"} marginLeft={8}>
                    Describe how not completing your degree has inhibited you
                    future earning capacity:
                </Box>
                <Box width={"45%"}>
                    <TextField
                        id="q18c1-input"
                        name="q18c1"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={6}
                        value={formValues.q18c1}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"45%"} marginLeft={4}>
                    d. I have a disability or chronic injury impacting my income
                    potential.
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q18d"
                        value={formValues.q18d}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"45%"} marginLeft={8}>
                    Describe the disability or injury and its effects on your
                    ability to work, and indicate whether you receive any
                    governmental benefits attributable to this disability or
                    injury:
                </Box>
                <Box width={"45%"}>
                    <TextField
                        id="q18e-input"
                        name="q18e"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={6}
                        value={formValues.q18e}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"45%"} marginLeft={4}>
                    e. I have been unemployed for at least five of the past ten
                    years.
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q18f"
                        value={formValues.q18f}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"45%"} marginLeft={8}>
                    Please explain your efforts to obtain employment.
                </Box>
                <Box width={"45%"}>
                    <TextField
                        id="q18g-input"
                        name="q18g"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={6}
                        value={formValues.q18g}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"100%"}>
                    2. Please answer the following additional questions:
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"45%"} marginLeft={4}>
                    a. I incurred student loans I am seeking to discharge in
                    pursuit of a degree from an institution that is now closed.
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q19a"
                        value={formValues.q19a}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"45%"} marginLeft={8}>
                    Describe how the school closure inhibited your future
                    earnings capacity:
                </Box>
                <Box width={"45%"}>
                    <TextField
                        id="q19b-input"
                        name="q19b"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={6}
                        value={formValues.q19b}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"45%"} marginLeft={4}>
                    b. I am not currently employed.
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q19c"
                        value={formValues.q19c}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"45%"} marginLeft={4}>
                    c. I am currently employed, but I am unable to obtain
                    employment in the field for which I am educated or have
                    received specialized training.
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q19d"
                        value={formValues.q19d}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"45%"} marginLeft={8}>
                    Describe reasons for inability to obtain such employment,
                    and indicate if you have ever been able to obtain such
                    employment:
                </Box>
                <Box width={"45%"}>
                    <TextField
                        id="q19e-input"
                        name="q19e"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={6}
                        value={formValues.q19e}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"45%"} marginLeft={4}>
                    d. I am currently employed, but my income is insufficient to
                    pay my loans and unlikely to increase to an amount necessary
                    to make substantial payments on the student loans I am
                    seeking to discharge.
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q19f"
                        value={formValues.q19f}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"45%"} marginLeft={8}>
                    Please explain why you believe this is so:
                </Box>
                <Box width={"45%"}>
                    <TextField
                        id="q19g-input"
                        name="q19g"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={6}
                        value={formValues.q19g}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"45%"} marginLeft={4}>
                    e. Other circumstances exist making it unlikely I will be
                    able to make payments for a significant part of the
                    repayment period.
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q19h"
                        value={formValues.q19h}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"45%"} marginLeft={8}>
                    Explain these circumstances:
                </Box>
                <Box width={"45%"}>
                    <TextField
                        id="q19i-input"
                        name="q19i"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={6}
                        value={formValues.q19i}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default Step5;

import React, { useContext } from "react";
import { Box } from "@mui/material";
import Context from "./Context";
import formatPhoneNumber from "./formatPhoneNumber";

function Completed() {
    const { selectedFirm } = useContext(Context);
    return (
        <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
        >
            <Box width={"35%"} textAlign={"center"} marginTop={25}>
                Thank you for completing the questionnaire.
                <br></br>
                <br></br>
                If you don’t hear from the attorney within 2 days, please call{" "}
                {formatPhoneNumber(selectedFirm.secretary_phone_number)}
            </Box>
        </Box>
    );
}

export default Completed;

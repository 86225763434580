import React, { useContext } from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import SelectFirm from "./SelectFirm";
import SelectedFirmWrapper from "./SelectedFirmWrapper";
import NotFound from "./NotFound";
import Context from "./Context";

function AppRouting() {
    return (
        <HashRouter>
            <Routes>
                <Route path="" element={<SelectFirm />} />
                <Route path="/:firm/*" element={<SelectedFirmWrapper />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </HashRouter>
    );
}

export default AppRouting;

import React from "react";
import {
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

function Step6({ formValues, handleInputChange }) {
    return (
        <Box marginY={3}>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                    marginBottom={4}
                    fontWeight={700}
                >
                    Please answer the following questions regarding your prior
                    efforts to repay loans to the best of your ability. Leave
                    answers blank if not applicable or unsure.
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                >
                    1. Since receiving the student loans at issue, I have made a
                    total of
                    <TextField
                        id="q21a-input"
                        name="q21a"
                        label=""
                        type="number"
                        size="small"
                        variant="standard"
                        value={formValues.q21a}
                        onChange={handleInputChange}
                        sx={{ marginX: 1 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                    in payments on the loans, including the following:
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                marginLeft={13}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                >
                    a. Regular monthly payments of
                    <TextField
                        id="q21b2-input"
                        name="q21b2"
                        label=""
                        type="number"
                        size="small"
                        variant="standard"
                        value={formValues.q21b2}
                        onChange={handleInputChange}
                        sx={{ marginX: 1 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                    each.
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                marginLeft={13}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                >
                    b. Additional payments, including
                    <TextField
                        id="q21c2-input"
                        name="q21c2"
                        label=""
                        type="number"
                        size="small"
                        variant="standard"
                        value={formValues.q21c2}
                        onChange={handleInputChange}
                        sx={{ marginX: 1 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                    ,{" "}
                    <TextField
                        id="q21c3-input"
                        name="q21c3"
                        label=""
                        type="number"
                        size="small"
                        variant="standard"
                        value={formValues.q21c3}
                        onChange={handleInputChange}
                        sx={{ marginX: 1 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                    , and{" "}
                    <TextField
                        id="q21c4-input"
                        name="q21c4"
                        label=""
                        type="number"
                        size="small"
                        variant="standard"
                        value={formValues.q21c4}
                        onChange={handleInputChange}
                        sx={{ marginX: 1 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                >
                    2. I have applied for
                    <TextField
                        id="q22a-input"
                        name="q22a"
                        label=""
                        type="number"
                        size="small"
                        variant="standard"
                        value={formValues.q22a}
                        onChange={handleInputChange}
                        sx={{ marginX: 1 }}
                    />
                    forbearances or deferments. I spent a period totaling
                    <TextField
                        id="q22b-input"
                        name="q22b"
                        label=""
                        type="number"
                        size="small"
                        variant="standard"
                        value={formValues.q22b}
                        onChange={handleInputChange}
                        sx={{ marginX: 1 }}
                    />{" "}
                    months in forbearance or deferment.
                </Box>
            </Box>
            <Box marginTop={4} marginX={5}>
                <Box>
                    3. I have attempted to contact the company that services or
                    collects on my student loans or the Department of Education
                    regarding payment options, forbearance
                </Box>
                <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                >
                    and deferment options, or loan consolidation at least
                    <TextField
                        id="q23-input"
                        name="q23"
                        label=""
                        type="number"
                        size="small"
                        variant="standard"
                        value={formValues.q23}
                        onChange={handleInputChange}
                        sx={{ marginX: 1 }}
                    />
                    times.
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"48%"}>
                    4. I have sought to enroll in one or more “Income Deferred
                    Repayment Programs” or similar repayment programs offered by
                    the Department of Education, including the following:
                </Box>
                <Box width={"48%"}>
                    <TextField
                        id="q24-input"
                        name="q24"
                        label="Description of Efforts"
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={6}
                        value={formValues.q24}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"48%"}>
                    5. [If you did not enroll in such a program]. I have not
                    enrolled in an “Income Deferred Repayment Program” or
                    similar repayment program offered by the Department of
                    Education for the following reasons:
                </Box>
                <Box width={"48%"}>
                    <TextField
                        id="q25-input"
                        name="q25"
                        label="Description of Reasons"
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={6}
                        value={formValues.q25}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"48%"}>
                    6. Describe any other facts indicating you have acted in
                    good faith in the past in attempting to repay the loan,
                    including efforts to obtain employment, maximize your
                    income, or minimize your expenses:
                </Box>
                <Box width={"48%"}>
                    <TextField
                        id="q26-input"
                        name="q26"
                        label="Description of Good Faith Efforts"
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={6}
                        value={formValues.q26}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default Step6;

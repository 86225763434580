import React, { useState, forwardRef, useContext } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    TextField,
    IconButton,
} from "@mui/material";
import axios from "axios";
import Context from "./Context";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

const api_url =
    "https://798qhvmd6a.execute-api.us-west-2.amazonaws.com/production";
const dev_api_url = "http://localhost:8000";

// CHANGE HERE WHEN DOING DEV WORK
const current_api_url = api_url;

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

function SaveFormArea({
    formValues,
    activeStep,
    setFormValues,
    setActiveStep,
}) {
    const { selectedFirm, user, setUser, setSelectedFirm } =
        useContext(Context);
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setSaveSuccessful(false);
        setSaveFailed(false);
        setLoadSuccessful(false);
        setLoadFailed(false);
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setIsCreateAccount(true);
        setTriedSubmitOnce(false);
        setEmailErrorMessage("");
        setPasswordErrorMessage("");
        setLoadingSubmit(false);
        setShowLogoutPage(false);
        setOpen(false);
    };
    const save = () => {
        setOpen(true);
    };
    const load = () => {
        setIsCreateAccount(false);
        setOpen(true);
    };
    const [email, setEmail] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [password, setPassword] = useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isCreateAccount, setIsCreateAccount] = useState(true);
    const [triedSubmitOnce, setTriedSubmitOnce] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [saveFailed, setSaveFailed] = useState(false);
    const [loadSuccessful, setLoadSuccessful] = useState(false);
    const [loadFailed, setLoadFailed] = useState(false);
    const [showLogoutPage, setShowLogoutPage] = useState(false);
    const changeToLogin = () => {
        setIsCreateAccount(false);
    };
    const changeToCreateAccount = () => {
        setIsCreateAccount(true);
    };
    const getHelperText = (type) => {
        if (isCreateAccount) {
            if (type === "email") {
                if (email.length < 1) {
                    return "Email field is required";
                } else if (!validateEmail(email)) {
                    return "Please input a valid email";
                } else {
                    return "";
                }
            } else if (type === "password") {
                if (password.length < 8) {
                    return "Password must be a least 8 characters in length";
                } else if (password != confirmPassword) {
                    return "Passwords do not match";
                } else {
                    return "";
                }
            }
        } else {
            if (type === "email") {
                if (email.length < 1) {
                    return "Email field is required";
                } else if (!validateEmail(email)) {
                    return "Please input a valid email";
                } else {
                    return "";
                }
            } else {
                return "";
            }
        }
    };
    const logout = () => {
        setShowLogoutPage(true);
        setUser(null);
    };
    const saveForm = (client) => {
        if (user !== null) {
            axios
                .get(`${current_api_url}/api/form_instance/${user}`)
                .then((response) => {
                    let data = {
                        id: response.data.id,
                        form_data: formValues,
                        current_step: activeStep,
                    };
                    axios
                        .put(`${current_api_url}/api/form_instance`, data)
                        .then(() => {
                            setSaveSuccessful(true);
                        })
                        .catch(() => {
                            setSaveFailed(true);
                        });
                })
                .catch((error) => {
                    if (error.response.data === "form not found") {
                        let data = {
                            client: user,
                            form_data: formValues,
                            current_step: activeStep,
                            submitted: false,
                        };
                        axios
                            .post(`${current_api_url}/api/form_instance`, data)
                            .then(() => {
                                setSaveSuccessful(true);
                            })
                            .catch(() => {
                                setSaveFailed(true);
                            });
                    } else {
                        setLoadFailed(true);
                    }
                });
        } else {
            let data = {
                client: client,
                form_data: formValues,
                current_step: activeStep,
                submitted: false,
            };
            axios
                .post(`${current_api_url}/api/form_instance`, data)
                .then(() => {
                    setSaveSuccessful(true);
                })
                .catch(() => {
                    setSaveFailed(true);
                })
                .finally(() => setUser(client));
        }
    };
    const loadForm = (client) => {
        axios
            .get(`${current_api_url}/api/form_instance/${client}`)
            .then((response) => {
                setFormValues(response.data.form_data);
                setActiveStep(response.data.current_step);
                setLoadSuccessful(true);
            })
            .catch(() => {
                setLoadFailed(true);
            })
            .finally(() => setUser(client));
    };
    const submit = () => {
        setTriedSubmitOnce(true);
        let valid = true;
        setEmailErrorMessage("");
        setPasswordErrorMessage("");
        if (isCreateAccount) {
            if (email.length < 1) {
                // set email required message
                setEmailErrorMessage("Email field is required");
                valid = false;
            } else if (!validateEmail(email)) {
                // set not valid email message
                setEmailErrorMessage("Please input a valid email");
                valid = false;
            }
            if (password.length < 8) {
                // set password must be 8 characters message
                setPasswordErrorMessage(
                    "Password must be a least 8 characters in length"
                );
                valid = false;
            } else if (password != confirmPassword) {
                // set passwords do not match message
                setPasswordErrorMessage("Passwords do not match");
                valid = false;
            }

            if (valid) {
                // CREATE NEW ACCOUNT
                setTriedSubmitOnce(false);
                let data = {
                    email: email,
                    password: password,
                    firm: selectedFirm.id,
                };
                setLoadingSubmit(true);
                axios
                    .post(`${current_api_url}/api/client_login`, data)
                    .then((response) => {
                        saveForm(response.data.email);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoadingSubmit(false);
                        if (
                            error?.response?.data?.email[0] ===
                            "client login with this email already exists."
                        ) {
                            setEmailErrorMessage(
                                "User with this email already exists"
                            );
                        }
                    });
            }
        } else {
            if (email.length < 1) {
                // set email required message
                setEmailErrorMessage("Email field is required");
                valid = false;
            } else if (!validateEmail(email)) {
                // set not valid email message
                setEmailErrorMessage("Please input a valid email");
                valid = false;
            }

            if (valid) {
                // LOGIN
                setTriedSubmitOnce(false);
                setLoadingSubmit(true);
                axios
                    .get(
                        `${current_api_url}/api/client_login/${email}/${password}`
                    )
                    .then((response) => {
                        if (selectedFirm.id !== response.data.firm.id) {
                            setSelectedFirm(response.data.firm);
                        }
                        loadForm(response.data.email);
                    })
                    .catch((error) => {
                        setLoadingSubmit(false);
                        if (error.response.data === "email not found") {
                            setEmailErrorMessage(
                                "User with this email does not exist"
                            );
                        } else if (
                            error.response.data === "password incorrect"
                        ) {
                            setPasswordErrorMessage("Password incorrect");
                        }
                    });
            }
        }
    };
    return (
        <Box
            height={"fit-content"}
            marginTop={3}
            display={"flex"}
            flexDirection={"column"}
        >
            <Button variant="outlined" onClick={save}>
                Save and continue later
            </Button>
            <Box height={10}></Box>
            <Button variant="outlined" color={"secondary"} onClick={load}>
                Login and continue now
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth
                PaperProps={{
                    sx: {
                        maxWidth: "35vw",
                        height: "46vh",
                        backgroundColor: "white",
                    },
                }}
            >
                {saveSuccessful ? (
                    <Box
                        width={"100%"}
                        height={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <CheckCircleTwoToneIcon
                            color="success"
                            sx={{ width: 50, height: 50 }}
                        />
                        <Box color="success.main" marginTop={2}>
                            Progress saved successfully!
                        </Box>
                        <Box marginTop={5} color={"#666666"}>
                            You may now close this window.
                        </Box>
                    </Box>
                ) : saveFailed ? (
                    <Box
                        width={"100%"}
                        height={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <CancelTwoToneIcon
                            color="error"
                            sx={{ width: 50, height: 50 }}
                        />
                        <Box color="error.main" marginTop={2}>
                            We're sorry. There was an error trying to save your
                            progress.
                        </Box>
                        <Box marginTop={5} color={"#666666"}>
                            Please close this window and try again.
                        </Box>
                    </Box>
                ) : loadSuccessful ? (
                    <Box
                        width={"100%"}
                        height={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <CheckCircleTwoToneIcon
                            color="success"
                            sx={{ width: 50, height: 50 }}
                        />
                        <Box color="success.main" marginTop={2}>
                            Progress loaded successfully!
                        </Box>
                        <Box marginTop={5} color={"#666666"}>
                            You may now close this window.
                        </Box>
                    </Box>
                ) : loadFailed ? (
                    <Box
                        width={"100%"}
                        height={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <CancelTwoToneIcon
                            color="error"
                            sx={{ width: 50, height: 50 }}
                        />
                        <Box color="error.main" marginTop={2}>
                            We're sorry. There was an error trying to load your
                            progress.
                        </Box>
                        <Box marginTop={5} color={"#666666"}>
                            Please close this window and try again.
                        </Box>
                    </Box>
                ) : user !== null ? (
                    <>
                        <DialogTitle sx={{ textAlign: "center", fontSize: 15 }}>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent
                            style={{
                                paddingTop: 25,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Box color={"#444444"}>
                                You are currently logged in as:{" "}
                                <b>
                                    <i>{user}</i>
                                </b>
                            </Box>
                            <Box marginTop={10}>
                                {loadingSubmit ? (
                                    <CircularProgress />
                                ) : (
                                    <Button
                                        variant={"contained"}
                                        onClick={() => {
                                            setLoadingSubmit(true);
                                            saveForm(user);
                                        }}
                                    >
                                        Click here to save progress
                                    </Button>
                                )}
                            </Box>
                            <Box marginTop={10}>
                                <Button color={"secondary"} onClick={logout}>
                                    Logout
                                </Button>
                            </Box>
                        </DialogContent>
                    </>
                ) : showLogoutPage ? (
                    <Box
                        width={"100%"}
                        height={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Box color="#555555" marginTop={2}>
                            You have been logged out.
                        </Box>
                        <Box marginTop={5} color={"#555555"}>
                            You may now close this window.
                        </Box>
                    </Box>
                ) : (
                    <>
                        <DialogTitle sx={{ textAlign: "center", fontSize: 15 }}>
                            {isCreateAccount
                                ? "CREATE AN ACCOUNT TO SAVE PROGRESS"
                                : "LOGIN TO LOAD PROGRESS"}
                        </DialogTitle>
                        <DialogContent
                            style={{
                                paddingTop: 8,
                            }}
                        >
                            <TextField
                                label="Email"
                                type="text"
                                size="small"
                                fullWidth
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    if (
                                        emailErrorMessage ===
                                            "User with this email already exists" ||
                                        emailErrorMessage ===
                                            "User with this email does not exist"
                                    ) {
                                        setTriedSubmitOnce(true);
                                    }
                                }}
                                sx={{ marginBottom: 2.5 }}
                                helperText={
                                    triedSubmitOnce
                                        ? getHelperText("email")
                                        : emailErrorMessage
                                }
                                error={
                                    triedSubmitOnce
                                        ? getHelperText("email").length > 0
                                        : emailErrorMessage.length > 0
                                }
                            />
                            <TextField
                                label="Password"
                                type="password"
                                size="small"
                                fullWidth
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    if (
                                        passwordErrorMessage ===
                                        "Password incorrect"
                                    ) {
                                        setTriedSubmitOnce(true);
                                    }
                                }}
                                sx={{ marginBottom: 2.5 }}
                                helperText={
                                    triedSubmitOnce
                                        ? getHelperText("password")
                                        : passwordErrorMessage
                                }
                                error={
                                    triedSubmitOnce
                                        ? getHelperText("password").length > 0
                                        : passwordErrorMessage.length > 0
                                }
                            />
                            {isCreateAccount && (
                                <TextField
                                    label="Confirm Password"
                                    type="password"
                                    size="small"
                                    fullWidth
                                    value={confirmPassword}
                                    onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                    sx={{ marginBottom: 2.5 }}
                                    helperText={
                                        triedSubmitOnce
                                            ? getHelperText("password")
                                            : passwordErrorMessage
                                    }
                                    error={
                                        triedSubmitOnce
                                            ? getHelperText("password").length >
                                              0
                                            : passwordErrorMessage.length > 0
                                    }
                                />
                            )}
                            {isCreateAccount ? (
                                <Box
                                    textAlign={"center"}
                                    color={"#555555"}
                                    sx={{
                                        ":hover": {
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        },
                                    }}
                                    onClick={changeToLogin}
                                >
                                    Already have an account? Login and load
                                    progress.
                                </Box>
                            ) : (
                                <Box
                                    textAlign={"center"}
                                    color={"#555555"}
                                    sx={{
                                        ":hover": {
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        },
                                    }}
                                    onClick={changeToCreateAccount}
                                >
                                    Don't have an account yet?
                                </Box>
                            )}
                        </DialogContent>
                        <DialogActions
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginX: 3,
                                marginBottom: 1,
                            }}
                        >
                            <Button
                                color="secondary"
                                onClick={handleClose}
                                variant="outlined"
                            >
                                Close
                            </Button>
                            <Button
                                onClick={submit}
                                disabled={loadingSubmit}
                                variant="contained"
                            >
                                Submit{" "}
                                {loadingSubmit && (
                                    <CircularProgress
                                        sx={{
                                            width: "20px !important",
                                            height: "20px !important",
                                            marginLeft: 1.5,
                                        }}
                                    />
                                )}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </Box>
    );
}

export default SaveFormArea;

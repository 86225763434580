import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function StartPage() {
    let { firm } = useParams();

    const navigate = useNavigate();

    const startform = () => {
        navigate(`/${firm}/form`);
    };
    return (
        <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
        >
            <Box width={"30%"} textAlign={"center"} marginTop={25}>
                In order to effectively process your case, we need accurate
                answers to all of these questions. You will need to reference
                your student loan information, recent pay stubs and last year’s
                tax return.
            </Box>
            <Button
                variant={"contained"}
                sx={{ marginTop: 5 }}
                color={"success"}
                onClick={() => startform()}
            >
                Start Questionnaire
            </Button>
        </Box>
    );
}

export default StartPage;

import React from "react";
import {
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
    Tooltip,
    IconButton,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function Step4({ formValues, handleInputChange }) {
    const isBold = (numPeople) => {
        if (formValues.dep_name_3 !== "") {
            if (numPeople === 4) {
                return true;
            } else {
                return false;
            }
        } else if (formValues.dep_name_2 !== "") {
            if (numPeople === 3) {
                return true;
            } else {
                return false;
            }
        } else if (formValues.dep_name_1 !== "") {
            if (numPeople === 2) {
                return true;
            } else {
                return false;
            }
        } else {
            if (numPeople === 1) {
                return true;
            } else {
                return false;
            }
        }
    };
    return (
        <Box marginY={3}>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
            >
                1. State whether your current monthly household expenses exceed
                the amounts listed below based on the number of people in your
                household:
                <Tooltip
                    title={
                        <Box fontSize={11}>
                            The living expenses listed have been adopted from
                            the Internal Revenue Service Collection Financial
                            Standards “National Standards” and “Local Standards”
                            for the year in which this form is issued. This form
                            is updated annually to reflect changes to these
                            expenses.
                        </Box>
                    }
                >
                    <IconButton>
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"50%"} marginLeft={4}>
                    a. My expenses for food exceed:
                    <br></br>
                    <ul>
                        <li
                            style={{
                                fontWeight: isBold(1) ? "bold" : 400,
                            }}
                        >
                            $431 (one person)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(2) ? "bold" : 400,
                            }}
                        >
                            $779 (two persons)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(3) ? "bold" : 400,
                            }}
                        >
                            $903 (three persons)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(4) ? "bold" : 400,
                            }}
                        >
                            $1028 (four persons)
                        </li>
                    </ul>
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q14ai"
                        value={formValues.q14ai}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="do exceed"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="do not exceed"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"50%"} marginLeft={4}>
                    b. My expenses for housekeeping supplies exceed:
                    <br></br>
                    <ul>
                        <li
                            style={{
                                fontWeight: isBold(1) ? "bold" : 400,
                            }}
                        >
                            $40 (one person)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(2) ? "bold" : 400,
                            }}
                        >
                            $82 (two persons)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(3) ? "bold" : 400,
                            }}
                        >
                            $74 (three persons)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(4) ? "bold" : 400,
                            }}
                        >
                            $85 (four persons)
                        </li>
                    </ul>
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q14aii"
                        value={formValues.q14aii}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="do exceed"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="do not exceed"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"50%"} marginLeft={4}>
                    c. My expenses for apparel & services exceed:
                    <br></br>
                    <ul>
                        <li
                            style={{
                                fontWeight: isBold(1) ? "bold" : 400,
                            }}
                        >
                            $99 (one person)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(2) ? "bold" : 400,
                            }}
                        >
                            $161(two persons)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(3) ? "bold" : 400,
                            }}
                        >
                            $206 (three persons)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(4) ? "bold" : 400,
                            }}
                        >
                            $279 (four persons)
                        </li>
                    </ul>
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q14aiii"
                        value={formValues.q14aiii}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="do exceed"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="do not exceed"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"50%"} marginLeft={4}>
                    d. My expenses for personal care products & services
                    (non-medical) exceed:
                    <br></br>
                    <ul>
                        <li
                            style={{
                                fontWeight: isBold(1) ? "bold" : 400,
                            }}
                        >
                            $45 (one person)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(2) ? "bold" : 400,
                            }}
                        >
                            $82 (two persons)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(3) ? "bold" : 400,
                            }}
                        >
                            $78 (three persons)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(4) ? "bold" : 400,
                            }}
                        >
                            $96 (four persons)
                        </li>
                    </ul>
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q14aiv"
                        value={formValues.q14aiv}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="do exceed"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="do not exceed"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"50%"} marginLeft={4}>
                    e. My expenses for miscellaneous expenses (not included
                    above) exceed:
                    <br></br>
                    <ul>
                        <li
                            style={{
                                fontWeight: isBold(1) ? "bold" : 400,
                            }}
                        >
                            $170 (one person)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(2) ? "bold" : 400,
                            }}
                        >
                            $306 (two persons)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(3) ? "bold" : 400,
                            }}
                        >
                            $349 (three persons)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(4) ? "bold" : 400,
                            }}
                        >
                            $412 (four persons)
                        </li>
                    </ul>
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q14av"
                        value={formValues.q14av}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="do exceed"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="do not exceed"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"50%"} marginLeft={4}>
                    f. My total expenses in the above categories exceed:
                    <br></br>
                    <ul>
                        <li
                            style={{
                                fontWeight: isBold(1) ? "bold" : 400,
                            }}
                        >
                            $785 (one person)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(2) ? "bold" : 400,
                            }}
                        >
                            $1410 (two persons)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(3) ? "bold" : 400,
                            }}
                        >
                            $1610 (three persons)
                        </li>
                        <li
                            style={{
                                fontWeight: isBold(4) ? "bold" : 400,
                            }}
                        >
                            $1900 (four persons in household)
                        </li>
                        Add $344 per each additional member if more than four in
                        household.
                    </ul>
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q14avi"
                        value={formValues.q14avi}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="do exceed"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="do not exceed"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"50%"} marginLeft={4}>
                    g. If you selected yes for any of the categories above and
                    you would like the AUSA to consider such additional expenses
                    as necessary, you may list the total expenses for any such
                    categories and explain the need for such expenses here:
                </Box>
                <Box width={"45%"}>
                    <TextField
                        id="q14c-input"
                        name="q14c"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={8}
                        value={formValues.q14c}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"50%"}>
                    2. My uninsured, out of pocket medical costs exceed:
                    <br></br>
                    <ul>
                        <li>$75 (per household member under 65)</li>
                        <li>$153 (per household member 65 or older)</li>
                    </ul>
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q14b"
                        value={formValues.q14b}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            {formValues.q14b === "yes" && (
                <Box
                    marginTop={4}
                    marginX={5}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                >
                    <Box width={"50%"} marginLeft={4}>
                        By answering 'YES' to the previous question, you may
                        list the household member’s total expenses and explain
                        the need for such expenses here.
                    </Box>
                    <Box width={"45%"}>
                        <TextField
                            id="q14b3-input"
                            name="q14b3"
                            label=""
                            type="text"
                            size="small"
                            fullWidth
                            multiline
                            rows={4}
                            value={formValues.q14b3}
                            onChange={handleInputChange}
                        />
                    </Box>
                </Box>
            )}
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"100%"} fontStyle={"italic"}>
                    If you filed a Form 122A-2 Chapter 7 Means Test or 122C-2
                    Calculation of Disposable Income in your bankruptcy case,
                    you may refer to lines 6 and 7 of those forms for
                    information.
                    <Tooltip
                        title={
                            <Box fontSize={11}>
                                Forms 122A-2 and 122C-2 are referred to
                                collectively here as the “Means Test.” If you
                                filed a Means Test in your bankruptcy case, you
                                may refer to it for information requested here
                                and in other expense categories below. If you
                                did not file a Means Test, you may refer to your
                                Schedule I and Form 106J – Your Expenses
                                (Schedule J) in the bankruptcy case, which may
                                also list information relevant to these
                                categories. You should only use information from
                                these documents if your expenses have not
                                changed since you filed them.
                            </Box>
                        }
                    >
                        <IconButton>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"100%"}>
                    3. My current monthly household expenses in the following
                    categories are as follows:
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    a: Taxes, Medicare and Social Security:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15ai-input"
                        name="q15ai"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15ai}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    b: Contributions to retirement accounts:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15aii1-input"
                        name="q15aii1"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15aii1}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={8}>
                    Are these contributions required as a condition of your
                    employment?
                </Box>
                <Box width={"30%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q15aii2"
                        value={formValues.q15aii2}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    c: Union dues:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15aiii-input"
                        name="q15aiii"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15aiii}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    d: Life insurance:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15aiv1-input"
                        name="q15aiv1"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15aiv1}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={8}>
                    Are the payments for a term policy covering your life?
                </Box>
                <Box width={"30%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q15aiv2"
                        value={formValues.q15aiv2}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    e: Court-ordered alimony and child support:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15av-input"
                        name="q15av"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15av}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    f: Health insurance:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15avi1-input"
                        name="q15avi1"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15avi1}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={8}>
                    Does the policy cover any persons other than yourself and
                    your family members?
                </Box>
                <Box width={"30%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q15avi2"
                        value={formValues.q15avi2}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"100%"} marginLeft={4}>
                    g: Other payroll deductions:
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-around"}
                alignItems={"center"}
            >
                <Box width={"35%"}>
                    <TextField
                        id="q15avii1a-input"
                        name="q15avii1a"
                        label="Description"
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.q15avii1a}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q15avii1b-input"
                        name="q15avii1b"
                        label="Amount"
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15avii1b}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-around"}
                alignItems={"center"}
            >
                <Box width={"35%"}>
                    <TextField
                        id="q15avii2a-input"
                        name="q15avii2a"
                        label="Description"
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.q15avii2a}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q15avii2b-input"
                        name="q15avii2b"
                        label="Amount"
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15avii2b}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-around"}
                alignItems={"center"}
            >
                <Box width={"35%"}>
                    <TextField
                        id="q15avii3a-input"
                        name="q15avii3a"
                        label="Description"
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.q15avii3a}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q15avii3b-input"
                        name="q15avii3b"
                        label="Amount"
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15avii3b}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"100%"}>
                    4. Housing Costs:
                    <Tooltip
                        title={
                            <Box fontSize={11}>
                                You should list the expenses you actually pay in
                                Housing Costs and Transportation Costs
                                categories. If these expenses have not changed
                                since you filed your Schedule J, you may refer
                                to the expenses listed there, including housing
                                expenses (generally on lines 4 through 6 of
                                Schedule J) and transportation expenses
                                (generally on lines 12, 15c and 17).
                            </Box>
                        }
                    >
                        <IconButton>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    a: Mortgage or rent payments:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15di-input"
                        name="q15di"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15di}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    b: Property taxes (if paid separately):
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15dii-input"
                        name="q15dii"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15dii}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    c: Homeowners or renters insurance (if paid separately):
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15diii-input"
                        name="q15diii"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15diii}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    d: Home maintenance and repair (average last 12 months'
                    amounts):
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15div-input"
                        name="q15div"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15div}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    e: Utilities (include monthly gas, electric, water, heating
                    oil, garbage collection, residential telephone service, cell
                    phone service, cable television, and internet service):
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15dv-input"
                        name="q15dv"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15dv}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"100%"}>5. Transportation Costs:</Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    a: Vehicle payments (itemize per vehicle):
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15ei-input"
                        name="q15ei"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15ei}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    b: Monthly average costs of operating vehicles (including
                    gas, routine maintenance, monthly insurance cost):
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15eii-input"
                        name="q15eii"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15eii}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    c: Public transportation costs:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15eiii-input"
                        name="q15eiii"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15eiii}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"100%"}>6. Other Necessary Expenses:</Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    a: Court-ordered alimony and child support payments (if not
                    deducted from pay):
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15fi-input"
                        name="q15fi"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15fi}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    b: Babysitting, day care, nursery and preschool costs:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15fii1-input"
                        name="q15fii1"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15fii1}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"60%"} marginLeft={8} fontStyle={"italic"}>
                    You may refer to line 21 of Form 122A-2 or 122C-2 or
                    Schedule J, line 8{" "}
                    <Tooltip
                        title={
                            <Box fontSize={11}>
                                Line 8 of Schedule J allows listing of expenses
                                for “childcare and children’s education costs.”
                                You should not list any educational expenses for
                                your children here, aside from necessary nursery
                                or preschool costs.
                            </Box>
                        }
                    >
                        <IconButton>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"60%"} marginLeft={8}>
                    Explain the circumstances making it necessary for you to
                    expend this amount:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15fii2-input"
                        name="q15fii2"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={8}
                        value={formValues.q15fii2}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    c: Health insurance (if not deducted from pay):
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15fiii1-input"
                        name="q15fiii1"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15fiii1}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={8}>
                    Does the policy cover any persons other than yourself and
                    your family members?
                </Box>
                <Box width={"30%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q15fiii2"
                        value={formValues.q15fiii2}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    d: Life insurance (if not deducted from pay):
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15fiv1-input"
                        name="q15fiv1"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15fiv1}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={8}>
                    Are the payments for a term policy covering your life?
                </Box>
                <Box width={"30%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q15fiv2"
                        value={formValues.q15fiv2}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    e: Dependent care (for elderly or disabled family members):
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15fv1-input"
                        name="q15fv1"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15fv1}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"60%"} marginLeft={8}>
                    Explain the circumstances making it necessary for you to
                    expend this amount:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15fv2-input"
                        name="q15fv2"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={8}
                        value={formValues.q15fv2}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    f: Payments on delinquent federal, state or local tax debt:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15fvi1-input"
                        name="q15fvi1"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15fvi1}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={8}>
                    Are these payments being made pursuant to an agreement with
                    the taxing authority?
                </Box>
                <Box width={"30%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q15fvi2"
                        value={formValues.q15fvi2}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    g: Payments on other student loans I am not seeking to
                    discharge:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15fvii-input"
                        name="q15fvii"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15fvii}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    h: Other expenses I believe necessary for a minimal standard
                    of living:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15fviii1-input"
                        name="q15fviii1"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q15fviii1}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"60%"} marginLeft={8}>
                    Explain the circumstances making it necessary for you to
                    expend this amount:
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="q15fviii2-input"
                        name="q15fviii2"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={8}
                        value={formValues.q15fviii2}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                >
                    7. After deducting the foregoing monthly expenses from my
                    household gross income, I have
                    <TextField
                        id="q16-input"
                        name="q16"
                        label=""
                        type="text"
                        size="small"
                        variant="standard"
                        value={formValues.q16}
                        onChange={handleInputChange}
                        sx={{ marginX: 1 }}
                    />
                    [no, or amount] remaining income.
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"100%"} display={"flex"} alignItems={"center"}>
                    8. In addition to the foregoing expenses, I anticipate I
                    will incur additional monthly expenses in the future for my,
                    and my dependents’, basic needs that are currently not met.
                    These include the following:
                    <Tooltip
                        title={
                            <Box fontSize={11}>
                                If you have forgone expenses for any basic needs
                                and anticipate that you will incur such expenses
                                in the future, you may list them here and
                                explain the circumstances making it necessary
                                for you to incur such expenses.
                            </Box>
                        }
                    >
                        <IconButton>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"100%"} marginLeft={4}>
                    <TextField
                        id="q17-input"
                        name="q17"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={8}
                        value={formValues.q17}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default Step4;

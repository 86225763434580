import React, { useState, useContext } from "react";
import { Box, Button } from "@mui/material";
import CustomAutocomplete from "./CustomAutocomplete";
import { useNavigate } from "react-router-dom";
import Context from "./Context";

function SelectFirm() {
    const { firmsList } = useContext(Context);
    const navigate = useNavigate();
    const [selectedFirm, setSelectedFirm] = useState(null);
    return (
        <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
        >
            <Box
                width={"30%"}
                textAlign={"center"}
                marginTop={25}
                fontWeight={600}
            >
                Please select the firm you are working with:
            </Box>
            <Box width={"30%"} marginTop={5}>
                <CustomAutocomplete
                    valuesList={firmsList}
                    value={selectedFirm}
                    setValue={(newValue) => setSelectedFirm(newValue)}
                />
            </Box>
            <Box marginTop={"250px"}>
                <Button
                    variant="contained"
                    color="success"
                    disabled={selectedFirm === null}
                    onClick={() => navigate(`/${selectedFirm.unique_url}`)}
                >
                    Continue
                </Button>
            </Box>
        </Box>
    );
}

export default SelectFirm;

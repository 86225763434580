import React from "react";
import { Box } from "@mui/material";
import "./App.css";
import { ContextProvider } from "./Context";
import AppRouting from "./AppRouting";

function App() {
    return (
        <Box backgroundColor={"white"} id="app">
            <ContextProvider>
                <AppRouting />
            </ContextProvider>
        </Box>
    );
}

export default App;

import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";

export default function CustomAutocomplete({ valuesList, value, setValue }) {
    const [inputValue, setInputValue] = useState("");

    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={valuesList}
            // filterOptions={valuesList}
            getOptionLabel={(option) => option.name}
            renderOption={(params, option) => (
                <Box {...params} fontSize={13}>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        style: { fontSize: 13 },
                    }}
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        style: { fontSize: 13 },
                    }}
                    variant="outlined"
                    size="small"
                />
            )}
            ListboxProps={{
                style: {
                    maxHeight: "200px",
                },
            }}
            fullWidth
            size="small"
        />
    );
}

import React, { useState } from "react";
import {
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
    Button,
    Tooltip,
    IconButton,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function Step7({ formValues, handleInputChange }) {
    const numOwnershipOptions = [1, 2, 3];
    const [currentNumOwnership, setCurrentNumOwnership] = useState(1);
    return (
        <Box marginY={3}>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                >
                    1. I own the following parcels of real estate (if multiple,
                    separate with commas):
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"30%"} marginLeft={4}>
                    a. Address:
                </Box>
                <Box width={"65%"}>
                    <TextField
                        id="q27a-input"
                        name="q27a"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.q27a}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"30%"} marginLeft={4}>
                    b. Owners:
                    <Tooltip
                        title={
                            <Box fontSize={11}>
                                List by name all owners of record (self and
                                spouse, for example)
                            </Box>
                        }
                    >
                        <IconButton>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box width={"65%"}>
                    <TextField
                        id="q27b-input"
                        name="q27b"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.q27b}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"30%"} marginLeft={4}>
                    c. Fair market value:
                </Box>
                <Box width={"65%"}>
                    <TextField
                        id="q27c-input"
                        name="q27c"
                        label=""
                        size="small"
                        fullWidth
                        value={formValues.q27c}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"30%"} marginLeft={4}>
                    d. Total balance of mortgages and other liens:
                </Box>
                <Box width={"65%"}>
                    <TextField
                        id="q27d-input"
                        name="q27d"
                        label=""
                        size="small"
                        fullWidth
                        value={formValues.q27d}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                >
                    2. I own the following motor vehicles (if multiple, separate
                    with commas):
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"30%"} marginLeft={4}>
                    a. Make and model:
                </Box>
                <Box width={"65%"}>
                    <TextField
                        id="q28a-input"
                        name="q28a"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.q28a}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"30%"} marginLeft={4}>
                    b. Fair market value:
                </Box>
                <Box width={"65%"}>
                    <TextField
                        id="q28b-input"
                        name="q28b"
                        label=""
                        size="small"
                        fullWidth
                        value={formValues.q28b}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"30%"} marginLeft={4}>
                    c. Total balance of vehicle loans and other liens:
                </Box>
                <Box width={"65%"}>
                    <TextField
                        id="q28c-input"
                        name="q28c"
                        label=""
                        size="small"
                        fullWidth
                        value={formValues.q28c}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"50%"}>
                    3. Total amount of retirement assets, held in 401k, IRA and
                    similar retirement accounts.
                </Box>
                <Box width={"45%"}>
                    <TextField
                        id="q29-input"
                        name="q29"
                        label=""
                        size="small"
                        type="number"
                        fullWidth
                        value={formValues.q29}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"55%"}>
                    4. I own interests in a corporation, limited liability
                    company, partnership, or other entity.
                </Box>
                <Box width={"45%"}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q30"
                        value={formValues.q30}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            {formValues.q30 === "yes" && (
                <>
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        marginX={6}
                        marginTop={4}
                    >
                        <Box
                            width={"30%"}
                            textAlign={"center"}
                            borderBottom={"1px solid black"}
                            paddingBottom={1}
                        >
                            NAME OF ENTITY
                        </Box>
                        <Box
                            width={"30%"}
                            textAlign={"center"}
                            borderBottom={"1px solid black"}
                            paddingBottom={1}
                        >
                            STATE INCORPORATED
                            <Tooltip
                                title={
                                    <Box fontSize={11}>
                                        The state, if any, in which the entity
                                        is incorporated. Partnerships, joint
                                        ventures and some other business
                                        entities might not be incorporated.
                                    </Box>
                                }
                            >
                                <IconButton>
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box
                            width={"30%"}
                            textAlign={"center"}
                            borderBottom={"1px solid black"}
                            paddingBottom={1}
                        >
                            TYPE AND % AGE INTEREST
                            <Tooltip
                                title={
                                    <Box fontSize={11}>
                                        For example, shares, membership
                                        interest, partnership interest.
                                    </Box>
                                }
                            >
                                <IconButton>
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    {numOwnershipOptions.map((option) => {
                        if (option <= currentNumOwnership) {
                            return (
                                <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    marginX={6}
                                    marginTop={3}
                                >
                                    <Box width={"30%"}>
                                        <TextField
                                            name={`q30_name_${option}`}
                                            label=""
                                            type="text"
                                            size="small"
                                            fullWidth
                                            value={
                                                formValues[`q30_name_${option}`]
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                    <Box width={"30%"}>
                                        <TextField
                                            name={`q30_state_${option}`}
                                            label=""
                                            type="text"
                                            size="small"
                                            fullWidth
                                            value={
                                                formValues[
                                                    `q30_state_${option}`
                                                ]
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                    <Box width={"30%"}>
                                        <TextField
                                            name={`q30_type_${option}`}
                                            label=""
                                            type="text"
                                            size="small"
                                            fullWidth
                                            value={
                                                formValues[`q30_type_${option}`]
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                </Box>
                            );
                        } else {
                            return <></>;
                        }
                    })}
                    {currentNumOwnership === numOwnershipOptions.length && (
                        <Box
                            marginTop={3}
                            marginX={6}
                            display={"flex"}
                            justifyContent={"flex-end"}
                            fontStyle={"italic"}
                        >
                            If you have an ownership interest in more than 3
                            businesses, notify your attorney after submitting
                            the form.
                        </Box>
                    )}
                    <Box
                        marginTop={3}
                        marginX={6}
                        display={"flex"}
                        justifyContent={
                            currentNumOwnership === 1
                                ? "flex-end"
                                : "space-between"
                        }
                    >
                        {currentNumOwnership > 1 && (
                            <Button
                                variant="outlined"
                                endIcon={<RemoveCircleOutlineIcon />}
                                color="error"
                                size="small"
                                onClick={() =>
                                    setCurrentNumOwnership(
                                        currentNumOwnership - 1
                                    )
                                }
                            >
                                Remove Item
                            </Button>
                        )}
                        {currentNumOwnership !== numOwnershipOptions.length && (
                            <Button
                                variant="contained"
                                endIcon={<AddCircleOutlineIcon />}
                                color="secondary"
                                size="small"
                                onClick={() =>
                                    setCurrentNumOwnership(
                                        currentNumOwnership + 1
                                    )
                                }
                            >
                                Add Another Item
                            </Button>
                        )}
                    </Box>
                </>
            )}
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                >
                    5. I currently am anticipating receiving a tax refund
                    totaling
                    <TextField
                        id="q31-input"
                        name="q31"
                        label=""
                        type="number"
                        size="small"
                        variant="standard"
                        value={formValues.q31}
                        onChange={handleInputChange}
                        sx={{ marginX: 1 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default Step7;

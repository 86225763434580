import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        background: "#DAE7E3",
        primary: { main: "#384661" },
        secondary: { main: "#A39273" },
        dark: { main: "#171926" },
        success: { main: "#758E4F" },
        error: { main: "#A62639" },
    },
    typography: {
        fontFamily: `"Helvetica", "sans-serif"`,
        fontSize: 12,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 400,
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </React.StrictMode>
);

import React from "react";
import {
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
    Checkbox,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

function Step8({ formValues, handleInputChange }) {
    return (
        <Box marginY={3}>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                >
                    I submit the following circumstances as additional support
                    for my effort to discharge my student loans as an “undue
                    hardship” under 11 U.S.C. §523(a)(8):
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Box width={"100%"}>
                    <TextField
                        id="q32-input"
                        name="q32"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        rows={10}
                        value={formValues.q32}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box>
                    <Checkbox
                        id="q33-input"
                        name="q33"
                        checked={formValues.q33}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box flex={1} marginLeft={3}>
                    Pursuant to 28 U.S.C. § 1746, I declare under penalty of
                    perjury that the foregoing is true and correct.
                </Box>
            </Box>
        </Box>
    );
}

export default Step8;

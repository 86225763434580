import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Context from "./Context";
import formatPhoneNumber from "./formatPhoneNumber";

export default function AppHeader() {
    const { selectedFirm } = useContext(Context);
    return (
        <AppBar
            position="static"
            sx={{
                background:
                    "linear-gradient(45deg, rgba(142,168,104,1) 0%, rgba(18,34,66,1) 100%)",
            }}
        >
            <Toolbar>
                <Box flexGrow={1}>
                    <a href={selectedFirm.firm_website_url} target="_blank">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                `/logos/${selectedFirm.firm_logo}`
                            }
                            height={60}
                        />
                    </a>
                </Box>
                <Box fontSize={13}>
                    Call{" "}
                    <b>
                        {formatPhoneNumber(selectedFirm.secretary_phone_number)}
                    </b>{" "}
                    or email <b>{selectedFirm.secretary_email}</b> if you have
                    questions
                </Box>
            </Toolbar>
        </AppBar>
    );
}

import React from "react";
import { Box } from "@mui/material";

function NotFound() {
    return (
        <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
        >
            <Box width={"35%"} textAlign={"center"} marginTop={25}>
                Page Not Found
            </Box>
        </Box>
    );
}

export default NotFound;

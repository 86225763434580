import React, { useState } from "react";
import { Box, TextField, Divider, Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

function Step1({ formValues, handleInputChange }) {
    const numDepOptions = [1, 2, 3];
    const [currentNumDeps, setCurrentNumDeps] = useState(0);
    return (
        <Box marginY={3}>
            <Box display={"flex"} justifyContent={"space-between"}>
                <Box width={"30%"}>
                    <TextField
                        id="firstname-input"
                        name="firstname"
                        label="First Name"
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.firstname}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="lastname-input"
                        name="lastname"
                        label="Last Name"
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.lastname}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="street-input"
                        name="street"
                        label="Street Address"
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.street}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                marginTop={3}
            >
                <Box width={"30%"}>
                    <TextField
                        id="city-input"
                        name="city"
                        label="City"
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.city}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="county-input"
                        name="county"
                        label="County"
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.county}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="state-input"
                        name="state"
                        label="State"
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.state}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Divider sx={{ marginY: 5, marginX: 3 }} />
            <Box textAlign={"center"} marginBottom={4}>
                My household includes the following persons (including myself):
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} marginX={6}>
                <Box
                    width={"30%"}
                    textAlign={"center"}
                    borderBottom={"1px solid black"}
                    paddingBottom={1}
                >
                    NAME
                </Box>
                <Box
                    width={"30%"}
                    textAlign={"center"}
                    borderBottom={"1px solid black"}
                    paddingBottom={1}
                >
                    AGE
                </Box>
                <Box
                    width={"30%"}
                    textAlign={"center"}
                    borderBottom={"1px solid black"}
                    paddingBottom={1}
                >
                    RELATIONSHIP
                </Box>
            </Box>
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                marginX={6}
                marginTop={3}
            >
                <Box width={"30%"}>
                    {formValues.firstname} {formValues.lastname}
                </Box>
                <Box width={"30%"}>
                    <TextField
                        id="age-self-input"
                        name="age_self"
                        label="Age"
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.age_self}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box width={"30%"}>Self</Box>
            </Box>
            {numDepOptions.map((option) => {
                if (option <= currentNumDeps) {
                    return (
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            marginX={6}
                            marginTop={3}
                        >
                            <Box width={"30%"}>
                                <TextField
                                    name={`dep_name_${option}`}
                                    label="Dependent Name"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    value={formValues[`dep_name_${option}`]}
                                    onChange={handleInputChange}
                                />
                            </Box>
                            <Box width={"30%"}>
                                <TextField
                                    name={`dep_age_${option}`}
                                    label="Age"
                                    type="number"
                                    size="small"
                                    fullWidth
                                    value={formValues[`dep_age_${option}`]}
                                    onChange={handleInputChange}
                                />
                            </Box>
                            <Box width={"30%"}>
                                <TextField
                                    name={`dep_rel_${option}`}
                                    label="Relationship"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    value={formValues[`dep_rel_${option}`]}
                                    onChange={handleInputChange}
                                />
                            </Box>
                        </Box>
                    );
                } else {
                    return <></>;
                }
            })}
            <Box
                marginTop={3}
                marginX={6}
                display={"flex"}
                justifyContent={
                    currentNumDeps === 0 ? "flex-end" : "space-between"
                }
            >
                {currentNumDeps > 0 && (
                    <Button
                        variant="outlined"
                        endIcon={<RemoveCircleOutlineIcon />}
                        color="error"
                        size="small"
                        onClick={() => setCurrentNumDeps(currentNumDeps - 1)}
                    >
                        Remove Dependent
                    </Button>
                )}
                {currentNumDeps !== numDepOptions.length && (
                    <Button
                        variant="contained"
                        endIcon={<AddCircleOutlineIcon />}
                        color="secondary"
                        size="small"
                        onClick={() => setCurrentNumDeps(currentNumDeps + 1)}
                    >
                        Add Another Dependent
                    </Button>
                )}
            </Box>
        </Box>
    );
}

export default Step1;

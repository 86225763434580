import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { Box, CircularProgress } from "@mui/material";

const Context = createContext();
export default Context;

const api_url =
    "https://798qhvmd6a.execute-api.us-west-2.amazonaws.com/production";
const dev_api_url = "http://localhost:8000";

// CHANGE HERE WHEN DOING DEV WORK
const current_api_url = api_url;

export const ContextProvider = ({ children }) => {
    const [firmsList, setFirmsList] = useState([]);
    const [selectedFirm, setSelectedFirm] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const getFirms = () => {
        axios.get(`${current_api_url}/api/registered_firm`).then((response) => {
            setFirmsList(
                response.data.filter((x) => x.name !== "AdminTestFirm")
            );
            // COMMENT OUT BELOW LINE WHEN DEPLOYING
            // setFirmsList(response.data);
            setLoading(false);
        });
    };
    const contextData = {
        firmsList: firmsList,
        selectedFirm: selectedFirm,
        setSelectedFirm: setSelectedFirm,
        user: user,
        setUser: setUser,
    };
    useEffect(() => {
        if (firmsList.length === 0) {
            setLoading(true);
            getFirms();
        }
    }, [loading]);

    return (
        <Context.Provider value={contextData}>
            {loading ? (
                <Box
                    width={"100vw"}
                    height={"100vh"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <CircularProgress />
                </Box>
            ) : (
                children
            )}
        </Context.Provider>
    );
};

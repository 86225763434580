import React from "react";
import {
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

function Step2({ formValues, handleInputChange }) {
    return (
        <Box marginY={3}>
            <Box
                marginTop={4}
                display={"flex"}
                justifyContent={"space-between"}
                marginX={5}
            >
                <Box width={"75%"} fontWeight={600}>
                    1. We have requested information about your student loans
                    from the Department of Education. We can rely on this
                    information if you wish. If you would like to input detailed
                    information about your student loans yourself, mark "yes".
                </Box>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="q4"
                    value={formValues.q4}
                    onChange={handleInputChange}
                >
                    <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                    />
                    <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                    />
                </RadioGroup>
            </Box>
            {formValues.q4 === "yes" && (
                <>
                    <Box
                        marginTop={4}
                        marginX={5}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Box width={"70%"}>
                            2. The outstanding balance of the student loan[s] I
                            am seeking to discharge in this adversary proceeding
                            is:
                        </Box>
                        <Box width={"25%"}>
                            <TextField
                                id="q5-input"
                                name="q5"
                                label=""
                                type="number"
                                size="small"
                                fullWidth
                                value={formValues.q5}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        marginTop={4}
                        marginX={5}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Box width={"70%"}>
                            3. The current monthly payment on such loan[s] is:
                        </Box>
                        <Box width={"25%"}>
                            <TextField
                                id="q6a-input"
                                name="q6a"
                                label=""
                                type="number"
                                size="small"
                                fullWidth
                                value={formValues.q6a}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        marginTop={4}
                        marginX={5}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Box width={"70%"} marginLeft={4}>
                            a. The loan[s] are scheduled to be repaid in (last
                            scheduled payment MM/YYYY):
                        </Box>
                        <Box width={"25%"}>
                            <TextField
                                id="q6b-input"
                                name="q6b"
                                label=""
                                type="text"
                                size="small"
                                fullWidth
                                value={formValues.q6b}
                                onChange={handleInputChange}
                            />
                        </Box>
                    </Box>
                    <Box marginTop={4} marginLeft={15}>
                        OR
                    </Box>
                    <Box
                        marginTop={4}
                        marginX={5}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Box width={"70%"} marginLeft={4}>
                            b. My student loan[s] went into default in
                            (MM/YYYY):
                        </Box>
                        <Box width={"25%"}>
                            <TextField
                                id="q6c-input"
                                name="q6c"
                                label=""
                                type="text"
                                size="small"
                                fullWidth
                                value={formValues.q6c}
                                onChange={handleInputChange}
                            />
                        </Box>
                    </Box>
                    <Box
                        marginTop={4}
                        marginX={5}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Box width={"70%"}>
                            4. Name of University where student loan[s] were
                            incurred:
                        </Box>
                        <Box width={"25%"}>
                            <TextField
                                id="q7a-input"
                                name="q7a"
                                label=""
                                type="text"
                                size="small"
                                fullWidth
                                value={formValues.q7a}
                                onChange={handleInputChange}
                            />
                        </Box>
                    </Box>
                    <Box
                        marginTop={4}
                        marginX={5}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Box width={"45%"} marginLeft={4}>
                            a. Degree type:
                        </Box>
                        <Box width={"50%"}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="q7b"
                                value={formValues.q7b}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel
                                    value="associates"
                                    control={<Radio />}
                                    label="Associates"
                                />
                                <FormControlLabel
                                    value="bachelors"
                                    control={<Radio />}
                                    label="Bachelors"
                                />
                                <FormControlLabel
                                    value="masters"
                                    control={<Radio />}
                                    label="Masters"
                                />
                                <FormControlLabel
                                    value="doctorate"
                                    control={<Radio />}
                                    label="Doctorate"
                                />
                            </RadioGroup>
                        </Box>
                    </Box>
                    <Box
                        marginTop={4}
                        marginX={5}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Box width={"70%"} marginLeft={4}>
                            b. Specialization:
                        </Box>
                        <Box width={"25%"}>
                            <TextField
                                id="q7c-input"
                                name="q7c"
                                label=""
                                type="text"
                                size="small"
                                fullWidth
                                value={formValues.q7c}
                                onChange={handleInputChange}
                            />
                        </Box>
                    </Box>
                    <Box
                        marginTop={4}
                        marginX={5}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Box width={"70%"} marginLeft={4}>
                            c. Date of completion (MM/YYYY):
                        </Box>
                        <Box width={"25%"}>
                            <TextField
                                id="q8a-input"
                                name="q8a"
                                label=""
                                type="text"
                                size="small"
                                fullWidth
                                value={formValues.q8a}
                                onChange={handleInputChange}
                            />
                        </Box>
                    </Box>
                    <Box marginTop={4} marginLeft={15}>
                        OR
                    </Box>
                    <Box
                        marginTop={4}
                        marginX={5}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Box width={"70%"} marginLeft={4}>
                            d. Date left course of study (MM/YYYY):
                        </Box>
                        <Box width={"25%"}>
                            <TextField
                                id="q8b-input"
                                name="q8b"
                                label=""
                                type="text"
                                size="small"
                                fullWidth
                                value={formValues.q8b}
                                onChange={handleInputChange}
                            />
                        </Box>
                    </Box>
                    <Box
                        marginTop={4}
                        marginX={5}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Box width={"70%"}>5. Are you currently employed?</Box>
                        <Box width={"25%"}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="q9a"
                                value={formValues.q9a}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label="No"
                                />
                            </RadioGroup>
                        </Box>
                    </Box>
                    {formValues.q9a === "yes" && (
                        <>
                            <Box
                                marginTop={4}
                                marginX={5}
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Box width={"60%"} marginLeft={4}>
                                    a. Current Occupation:
                                </Box>
                                <Box width={"35%"}>
                                    <TextField
                                        id="q9b-input"
                                        name="q9b"
                                        label=""
                                        type="text"
                                        size="small"
                                        fullWidth
                                        value={formValues.q9b}
                                        onChange={handleInputChange}
                                    />
                                </Box>
                            </Box>
                            <Box
                                marginTop={4}
                                marginX={5}
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Box width={"60%"} marginLeft={4}>
                                    b. Employer name:
                                </Box>
                                <Box width={"35%"}>
                                    <TextField
                                        id="q9c-input"
                                        name="q9c"
                                        label=""
                                        type="text"
                                        size="small"
                                        fullWidth
                                        value={formValues.q9c}
                                        onChange={handleInputChange}
                                    />
                                </Box>
                            </Box>
                            <Box
                                marginTop={4}
                                marginX={5}
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Box width={"60%"} marginLeft={4}>
                                    c. Employer address:
                                </Box>
                                <Box width={"35%"}>
                                    <TextField
                                        id="q9d-input"
                                        name="q9d"
                                        label=""
                                        type="text"
                                        size="small"
                                        fullWidth
                                        value={formValues.q9d}
                                        onChange={handleInputChange}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}
                </>
            )}
        </Box>
    );
}

export default Step2;

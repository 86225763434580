import React from "react";
import {
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
    Tooltip,
    IconButton,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

function Step3({ formValues, handleInputChange }) {
    const getTotalGrossIncome = () => {
        let total = 0;
        total = !isNaN(parseFloat(formValues.q11a))
            ? (total += parseFloat(formValues.q11a))
            : total;
        total = !isNaN(parseFloat(formValues.q11b))
            ? (total += parseFloat(formValues.q11b))
            : total;
        total = !isNaN(parseFloat(formValues.q11c))
            ? (total += parseFloat(formValues.q11c))
            : total;
        total = !isNaN(parseFloat(formValues.q11d1))
            ? (total += parseFloat(formValues.q11d1))
            : total;
        total = !isNaN(parseFloat(formValues.q11e1))
            ? (total += parseFloat(formValues.q11e1))
            : total;
        total = !isNaN(parseFloat(formValues.q11f1))
            ? (total += parseFloat(formValues.q11f1))
            : total;
        total = !isNaN(parseFloat(formValues.q11g))
            ? (total += parseFloat(formValues.q11g))
            : total;
        total = !isNaN(parseFloat(formValues.q11h))
            ? (total += parseFloat(formValues.q11h))
            : total;
        total = !isNaN(parseFloat(formValues.q11i))
            ? (total += parseFloat(formValues.q11i))
            : total;
        total = !isNaN(parseFloat(formValues.q11j))
            ? (total += parseFloat(formValues.q11j))
            : total;
        return total;
    };
    return (
        <Box marginY={3}>
            <Box textAlign={"center"}>
                YOU MUST LIST <b>FULL</b> MONTHLY INCOME (NOT 4 WEEKS OF INCOME
                – THERE ARE 4.333 WEEKS IN A MONTH), IF YOU GET PAID EVERY WEEK,
                YOU NEED TO MULTIPLY YOUR WEEKLY WAGES BY 4.333, IF YOU GET PAID
                EVERY 2 WEEKS, YOU MULTIPLY BY 2.166
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"}>
                    1. Current monthly household total gross income:
                    <Tooltip
                        title={
                            <Box fontSize={11}>
                                “Gross income” means your income before any
                                payroll deductions (for taxes, Social Security,
                                health insurance, etc.) or deductions from other
                                sources of income. You may have included
                                information about your gross income on documents
                                previously filed in your bankruptcy case,
                                including Form B 106I, Schedule I - Your Income
                                (Schedule I). If you filed your Schedule I
                                within the past 18 months and the income
                                information on those documents has not changed,
                                you may refer to that document for the income
                                information provided here. If you filed Schedule
                                I more than 18 months prior to this Attestation,
                                or your income has changed, you should provide
                                your new income information.
                            </Box>
                        }
                    >
                        <IconButton>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box width={"35%"}>
                    {formatter.format(getTotalGrossIncome())}
                    {/* <TextField
                        id="q11-input"
                        name="q11"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q11}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    /> */}
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    a. Gross monthly income from employment:
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11a-input"
                        name="q11a"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q11a}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    b. Unemployment benefits:
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11b-input"
                        name="q11b"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q11b}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    c. Social security benefits:
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11c-input"
                        name="q11c"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q11c}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"20%"} marginLeft={4}>
                    d. Other income 1:
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11d2-input"
                        name="q11d2"
                        label="Description"
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.q11d2}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11d1-input"
                        name="q11d1"
                        label="Amount"
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q11d1}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"20%"} marginLeft={4}>
                    e. Other income 2:
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11e2-input"
                        name="q11e2"
                        label="Description"
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.q11e2}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11e1-input"
                        name="q11e1"
                        label="Amount"
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q11e1}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"20%"} marginLeft={4}>
                    f. Other income 3:
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11f2-input"
                        name="q11f2"
                        label="Description"
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.q11f2}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11f1-input"
                        name="q11f1"
                        label="Amount"
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q11f1}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    g. Gross income from employment of other household members:
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11g-input"
                        name="q11g"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q11g}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    h. Unemployment benefits recieved by other household
                    members:
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11h-input"
                        name="q11h"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q11h}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    i. Social security benefits recieved by other household
                    members:
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11i-input"
                        name="q11i"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q11i}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"} marginLeft={4}>
                    j. Other income from any other source recieved by other
                    household members:
                </Box>
                <Box width={"35%"}>
                    <TextField
                        id="q11j-input"
                        name="q11j"
                        label=""
                        type="number"
                        size="small"
                        fullWidth
                        value={formValues.q11j}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"60%"}>
                    2. The current monthly household gross income stated above
                    (select which applies):
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"100%"} marginLeft={4}>
                    <RadioGroup
                        column
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="q12"
                        value={formValues.q12}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel
                            value="q12a"
                            control={<Radio />}
                            label="Includes a monthly average of the gross income shown on the most recent tax
                            return[s] filed for myself and other members of my household, which are attached, and
                            the amounts stated on such tax returns have not changed materially since the tax year of
                            such returns"
                            sx={{ marginBottom: 2 }}
                        />
                        <FormControlLabel
                            value="q12b"
                            control={<Radio />}
                            label="Represents an average amount calculated from the most recent two months of
                            gross income stated on four (4) consecutive paystubs from my current employment,
                            which are attached"
                            sx={{ marginBottom: 2 }}
                        />
                        <FormControlLabel
                            value="q12c"
                            control={<Radio />}
                            label="My current monthly household gross income is not accurately reflected on either
                            recent tax returns or paystubs from current employment, and I have submitted instead the
                            following documents verifying current gross household income from employment of
                            household members:"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"25%"} marginLeft={10}>
                    Documents Submitted:
                </Box>
                <Box width={"70%"}>
                    <TextField
                        id="q12d-input"
                        name="q12d"
                        label=""
                        type="text"
                        size="small"
                        disabled={formValues.q12 !== "q12c"}
                        fullWidth
                        value={formValues.q12d}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"100%"}>
                    3. Documents submitted verifying the sources of income other
                    than income from employment, as such income is not shown on
                    most recent tax return[s] or paystubs:
                </Box>
            </Box>
            <Box
                marginTop={4}
                marginX={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box width={"100%"} marginLeft={10}>
                    <TextField
                        id="q13-input"
                        name="q13"
                        label=""
                        type="text"
                        size="small"
                        fullWidth
                        value={formValues.q13}
                        onChange={handleInputChange}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default Step3;
